import React from 'react'

import { graphql } from 'gatsby'
import FAQs from 'Section/FAQs'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import getTranslation from '../../src/utility/language-picker'

export const query = graphql`
  query FAQPageQuery {
    page: sanityFaqPage(_id: { regex: "/(drafts.|)faqPage/" }) {
      id
      title {
        value
        _key
      }
      _rawDescription
      _rawBody
      faqs {
        category {
          value
          _key
        }
        faqs {
          question {
            value
            _key
          }
          _rawAnswer
        }
      }
    }
  }
`

const AboutPage = (props) => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = getTranslation(data && data.page)

  if (!page) {
    throw new Error(
      'Missing "Faq" page data. Open the studio at http://localhost:3333 and add "Faq" page data and restart the development server.'
    )
  }

  const translateFaq = (faq) =>
    faq
      ? {
          ...getTranslation(faq),
          faqs: faq.faqs?.map(translateFaq)
        }
      : faq

  page.faqs?.map((faq, i) => (page.faqs[i] = translateFaq(faq)))

  return (
    <Layout shouldHaveSpaceForNav disableFooterOverlay>
      <SEO title={page.title} slug="/faqs" />
      <FAQs
        title={page.title}
        description={page._rawDescription}
        faqs={page.faqs}
        body={page._rawBody}
      />
    </Layout>
  )
}

export default AboutPage
